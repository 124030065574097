import { useState, useEffect } from 'react';

let listeners = [];
let state = {};

const setState = (newState) => {
    state = { ...state, ...newState };
    listeners.forEach(listener => {
        if (typeof listener === 'function') {
            listener(state);
        }
    });
};

const useEvent = () => {
    const newListener = useState();
    useEffect(() => {
        listeners.push(newListener);
    });
    return [state, setState];
};

export default useEvent;