import React from "react";
import {
    FaTwitter,
    FaFacebookF,
    FaInstagram,
    FaYoutube,
    FaSlack
} from 'react-icons/fa';
import {graphql, Link, useStaticQuery} from "gatsby";
import './Footer.scss';
import useEvent from "../../hooks/useEvent";
import {currentLocale, t} from "../../i18n";

const Footer = () => {
    const data = useStaticQuery(graphql`
		query Footer {
			site {
				siteMetadata {
					twitter
					facebook
					instagram
					youtube
					slack
				}
			}
		}
	`);
    const [event] = useEvent();

    return (
        <footer className="bg bg-small bg-grey">
            <div className="container footer">
                <div className="content footer__content">
                    <div className="item item-3 footer__date">
                        <span>
                            {event.location ? `${event.startDate} - ${event.endDate} ${event.year} in ${event.location.city}` : 'DO!Hack'}
                        </span>
                    </div>
                    <div className="item item-3">
                        <div className="content footer__icon-content">
                            <a href={data.site.siteMetadata.twitter} className="footer__icon"><FaTwitter /></a>
                            <a href={data.site.siteMetadata.facebook} className="footer__icon"><FaFacebookF /></a>
                            <a href={data.site.siteMetadata.instagram} className="footer__icon"><FaInstagram /></a>
                            <a href={data.site.siteMetadata.youtube} className="footer__icon"><FaYoutube /></a>
                            <a href={data.site.siteMetadata.slack} className="footer__icon"><FaSlack /></a>
                        </div>
                    </div>
                    <div className="item item-3">
                        <div className="content content-col footer__links--right">
                            <Link to="/imprint" className="item link footer__link">{t('footer.imprint')}</Link>
                            <Link to="/privacy-policy" className="item link footer__link">{t('footer.privacyPolicy')}</Link>
                            <a href="https://berlincodeofconduct.org/" className="item link footer__link">Berlin Code of Conduct</a>
                            <Link to={`/${currentLocale()}/past-events`} className="item link footer__link">{t('footer.pastEvents')}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
