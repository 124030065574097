export default {
    faq: {
        subtitle: 'Questions and answer'
    },
    footer: {
        imprint: 'Imprint',
        privacyPolicy: 'Privacy policy',
        pastEvents: 'Past events'
    },
    header: {
        register: 'Register',
        becomeSponsor: 'Become a sponsor',
        toCurrentEvent: 'To current event'
    },
    language: {
        german: 'German',
        english: 'English'
    },
    map: {
        moreInfo: 'More Information'
    },
    pastEvents: {
        toEvent: 'To the event',
        title: 'Past DO!Hack events',
        subtitle: 'Here is an overview over all our past events. For more impressions visit our'
    },
    speaker: {
        viewTalk: 'View talk'
    },
    sponsorship: {
        date: 'Date',
        topic: 'Topic',
        procedure: 'Procedure',
        possibilities: 'Possibilities',
        contact: {
            subtitle: 'You like DO!Hack and you want to sponsor us? Contact us!'
        }
    }
}
