import enGB from './en-GB';
import de from './de';

const messages = {
  'en-GB': enGB,
  'de': de
};
const supportedLocales = ['de', 'en-GB'];
const defaultLocale = 'en-GB';

export function currentLocale() {
  if (typeof window === 'undefined') {
    return defaultLocale;
  }
  if (supportedLocales.includes(window.location.pathname.split('/')[1])) {
    return window.location.pathname.split('/')[1];
  } else {
    return defaultLocale;
  }
}

export function t(key) {
  if (messages[currentLocale()]) {
    let message = messages[currentLocale()];
    let keys = key.split('.');
    for (let i = 0, n = keys.length; i < n; ++i) {
      let keyPart = keys[i];
      if (keyPart in message) {
        message = message[keyPart];
      } else {
        return;
      }
    }
    return message;
  } else {
    throw new Error(`Language key '${key}' for locale '${currentLocale()}' not found`);
  }
}
