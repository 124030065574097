export default {
    faq: {
        subtitle: 'Fragen und Antworten'
    },
    footer: {
        imprint: 'Impressum',
        privacyPolicy: 'Datenschutzerklärung',
        pastEvents: 'Vergangene Events'
    },
    header: {
        register: 'Registrieren',
        becomeSponsor: 'Sponsor werden',
        toCurrentEvent: 'Zum aktuellen Event'
    },
    language: {
        german: 'Deutsch',
        english: 'Englisch'
    },
    map: {
        moreInfo: 'Mehr Informationen'
    },
    pastEvents: {
        toEvent: 'Zum Event',
        title: 'Vergangene DO!Hack Events',
        subtitle: 'Hier ist eine Übersicht über alle vergangenen DO!Hack. Für mehr Eindrücke besucht unseren'
    },
    speaker: {
        viewTalk: 'Talk anschauen'
    },
    sponsorship: {
        date: 'Datum',
        topic: 'Thema',
        procedure: 'Ablauf',
        possibilities: 'Möglichkeiten',
        contact: {
            subtitle: 'Ihnen gefällt DO!Hack und Sie haben Lust uns zu sponsern? Kontaktieren Sie uns!'
        }
    }
}
