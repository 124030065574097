import React, {useEffect} from 'react';
import {
    FaTwitter,
    FaFacebookF,
    FaInstagram,
    FaYoutube,
    FaSlack,
    FaBars,
    FaTimes
} from 'react-icons/fa';
import {useStaticQuery, graphql, Link} from 'gatsby'
import en from '../../images/gb.svg';
import de from '../../images/de.svg';

import './Navbar.scss';
import {currentLocale, t} from "../../i18n";

const Navbar = (props) => {
    const data = useStaticQuery(graphql`
		query NavbarQuery {
			site {
				siteMetadata {
					twitter
					facebook
					instagram
					youtube
					slack
				}
			}
		}
	`);

    function toggleNavbar() {
        document.querySelectorAll(".navbar__menu").forEach(menu => {
            if (menu.classList.contains('responsive')) {
                menu.classList.remove('responsive');
            } else {
                menu.classList.add('responsive');
            }
        });
    }

    const navbar = (
        <nav className="navbar__container">
            <div className="navbar">
                <div className="navbar__menu navbar__social-menu">
                    <Link to={`/${currentLocale()}/`} className="link">
                        <div className="navbar__brand">
                            DO!<span className="navbar__brand-logo--hover">Hack</span>
                        </div>
                    </Link>
                    <a href={data.site.siteMetadata.twitter} className="navbar__item navbar__social-item">
                        <div className="navbar__icon navbar__social-icon">
                            <FaTwitter />
                        </div>
                    </a>
                    <a href={data.site.siteMetadata.facebook} className="navbar__item navbar__social-item">
                        <div className="navbar__icon navbar__social-icon">
                            <FaFacebookF />
                        </div>
                    </a>
                    <a href={data.site.siteMetadata.instagram} className="navbar__item navbar__social-item">
                        <div className="navbar__icon navbar__social-icon">
                            <FaInstagram />
                        </div>
                    </a>
                    <a href={data.site.siteMetadata.youtube} className="navbar__item navbar__social-item">
                        <div className="navbar__icon navbar__social-icon">
                            <FaYoutube />
                        </div>
                    </a>
                    <a href={data.site.siteMetadata.slack} className="navbar__item navbar__social-item">
                        <div className="navbar__icon navbar__social-icon">
                            <FaSlack />
                        </div>
                    </a>
                    <div className="dropdown navbar__flag navbar__item navbar__social-item">
                        <div className="navbar__icon navbar__social-icon">
                            <span className="flag-image">{currentLocale()}</span>
                            <div className="dropdown-content">
                                <ul className="language-list">
                                    <li className="language-list__item">
                                        <Link className="link link-black language-link" to={`/de/${props.currentPage}`}>
                                            <img className="flag-image" src={de} alt="de"/>
                                            <span className="language-name">{t('language.german')}</span>
                                        </Link>
                                    </li>
                                    <li className="language-list__item">
                                        <Link className="link link-black language-link" to={`/en-GB/${props.currentPage}`}>
                                            <img className="flag-image" src={en} alt="en-GB"/>
                                            <span className="language-name">{t('language.english')}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="navbar__menu navbar__link-menu">
                    <button className="navbar__item navbar__toggle" onClick={toggleNavbar}>
                        <div className="navbar__icon navbar__icon--closed">
                            <FaBars />
                        </div>
                        <div className="navbar__icon navbar__icon--open">
                            <FaTimes />
                        </div>
                    </button>
                    {props.children}
                </div>
            </div>
        </nav>
    );

    useEffect(() => {
        let scrollSpies = [];
        document.querySelectorAll(".scrollSpy").forEach(element => {
            scrollSpies.push({offset: element.offsetTop - 128, id: element.id});
        });

        window.onscroll = function() {
            let scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
            if (scrollSpies.length > 0) {
                if (scrollPosition < scrollSpies[0].offset) {
                    document.querySelectorAll('.navbar__menu .navbar__item').forEach(element => {
                        element.classList.remove('navbar__item--active');
                    });
                }
                scrollSpies.forEach(spy => {
                    if (spy.offset <= scrollPosition) {
                        document.querySelectorAll('.navbar__menu .navbar__item').forEach(element => {
                            element.classList.remove('navbar__item--active');
                        });
                        document.querySelector(`.${spy.id}`).classList.add('navbar__item--active');
                    }
                });
            }
        };
    }, []);
    return navbar;
};

export default Navbar;
